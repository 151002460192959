<template>
  <b-card class="simulation-card" body-class="p-4">
    <b-card-body class="p-0 d-flex flex-column">
      <b-img class="mx-auto mb-3" :src="currentAvatarUrl" style="width: 60px"></b-img>
      <p class="mb-0 font-light text-muted">{{ client }}</p>
      <h4>{{ title }}</h4>
      <sim-property class="mb-1" icon="location-dot" :value="location"></sim-property>
      <sim-property
        class="mb-1"
        icon="circle-small"
        iconFilled
        :value="statusName"
        :variant="setSimulationStatusVariant(status)"
      ></sim-property>
      <sim-property
        class="mb-1"
        icon="calendar-days"
        :value="`${startDate} - ${endDate}`"
      ></sim-property>
      <sim-property class="mb-1" icon="users" :value="participants"></sim-property>
      <sim-property class="mb-2" icon="crown" :value="facilitators"></sim-property>
      <p
        class="border border-radius p-2 d-flex mb-0 copy-link"
        :id="`game-${code}`"
        @click.stop="copyToClipboard"
      >
        <span class="align-self-center font-extra-light">{{ gameLink }}</span>
        <i class="align-self-center fal fa-copy fa-xl ml-auto"></i>
      </p>

      <b-popover ref="popover" :target="`game-${code}`">
        <span>Copied</span>
      </b-popover>

      <b-dropdown
        v-if="showActions"
        class="simulation-card-actions m-2"
        right
        variant="empty"
        toggle-class="px-2 py-1"
        menu-class="btn-shadow"
        no-caret
      >
        <template slot="button-content">
          <i class="fas fa-ellipsis"></i>
        </template>
        <b-dropdown-item @click.stop="onSimulationEdit">
          <span>Edit Simulation</span>
        </b-dropdown-item>
        <b-dropdown-item @click.stop="onManageUserClick">
          <span>Manage Users</span>
        </b-dropdown-item>
        <b-dropdown-item @click.stop="onSimulationDelete">
          <span>Delete Simulation</span>
        </b-dropdown-item>
      </b-dropdown>
    </b-card-body>

    <confirm-modal
      actionButton="Delete"
      actionButtonVariant="danger"
      v-model="showConfirmModal"
      :processing="processingSimulation"
      @actionCanceled="onActionCancel"
      @actionConfirmed="onActionConfirm"
    >
      <template v-if="deletingSimulation" v-slot:body>
        <h6>
          Are you sure you want to delete <b>{{ deletingSimulation.name }}</b
          >?
        </h6>
        <p>This action cannot be undone once confirmed.</p>
      </template>
      ></confirm-modal
    >
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { simulationStatus } from '@/constants/config'
import ActionConfirmModal from '@/components/Modals/ActionConfirmModal.vue'
import SimulationCardProperty from './SimulationCardProperty.vue'
export default {
  components: {
    'confirm-modal': ActionConfirmModal,
    'sim-property': SimulationCardProperty
  },
  props: {
    avatar: {
      type: String,
      default: ''
    },
    client: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    },
    endDate: {
      type: String,
      default: ''
    },
    facilitators: {
      type: Number,
      default: 0
    },
    location: {
      type: String,
      default: ''
    },
    participants: {
      type: Number,
      default: 0
    },
    showActions: {
      type: Boolean,
      default: true
    },
    startDate: {
      type: String,
      default: ''
    },
    status: {
      type: [Number, String],
      default: 0
    },
    statusName: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      appUrl: config.VUE_APP_URL || process.env.VUE_APP_URL,
      currentAvatarUrl: '',
      defaultAvatarUrl: '/assets/img/logo/logo.png',
      deletingSimulation: null,
      popoverVisible: false,
      showConfirmModal: false,
      simulationStatus
    }
  },
  mounted() {
    this.checkImageUrl(this.avatar)
  },
  methods: {
    ...mapActions(['deleteSimulation']),
    checkImageUrl(url) {
      const img = new Image()
      img.onload = () => {
        this.currentAvatarUrl = url
      }
      img.onerror = () => {
        this.currentAvatarUrl = this.defaultAvatarUrl
      }
      img.src = url
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.gameLink)
      this.$refs.popover.$emit('open')
      setTimeout(() => {
        this.$refs.popover.$emit('close')
      }, 2000) // Hide after 2 seconds
    },
    onActionCancel() {
      this.triggerConfirmModal(false)
    },
    async onActionConfirm() {
      await this.deleteSimulation(this.deletingSimulation.id)
      this.triggerConfirmModal(false)
    },
    onManageUserClick() {
      this.$router.push(this.userMangementLink)
    },
    onSimulationDelete() {
      const simulation = this.simulations.find((sim) => sim.code === this.code)
      if (!simulation) {
        return
      } else {
        this.deletingSimulation = _.cloneDeep(simulation)
      }

      this.triggerConfirmModal(true)
    },
    onSimulationEdit() {
      this.$emit('edit')
    },
    setSimulationStatusVariant(status) {
      return this.simulationStatus[status].variant
    },
    triggerConfirmModal(value) {
      this.showConfirmModal = value
    }
  },
  computed: {
    ...mapGetters('loading', ['processingSimulation']),
    ...mapGetters(['simulations']),
    avatarUrl() {
      return this.avatar || '/assets/img/logo/logo.png'
    },
    gameLink() {
      return `${this.appUrl}/my-simulations/${this.code}/home`
    },
    userMangementLink() {
      return `my-simulations/${this.code}/management/users`
    }
  }
}
</script>

<style lang="scss" scoped>
.simulation-card {
  &:hover {
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    background-color: rgb(251, 251, 251);
  }

  .copy-link {
    &:hover {
      cursor: pointer;
      background-color: rgb(228, 228, 228);
    }
  }

  &-actions {
    position: absolute;
    top: 0;
    right: 0;

    &:hover {
      cursor: pointer;
      background-color: rgb(228, 228, 228);
      border-radius: 20%;
    }
  }
}
</style>
